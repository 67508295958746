<template>
  <el-card class="statement-item">
    <header @click="!expanded ? expanded = true : null">
      <div class="flex-between">
        <div class="ms-text-l mr-5">
          #{{ data.number }} | {{ data.registration.accounts[0] ? data.registration.accounts[0].accountNumber : null }}
        </div>

        <el-tag
          effect="dark"
          size="mini"
          class="right ml-10">
          {{ data.registration.type }}
        </el-tag>
      </div>

      <div class="ms-text">
        {{ $date(data.createdAt, 'LL') }}
      </div>
    </header>

    <el-collapse-transition>
      <section v-show="expanded" class="mt-20">
        <ul class="gn-list">
          <li
            v-for="(item, index) in infoList"
            :key="index">
            <label>{{ item.label }}:</label>
            <span>{{ item.text || 'N/A' }}</span>
          </li>
          <li
            v-if="data.file">
            <label>Filename:</label>
            <span>
              <a :href="`${$server}download/Statement/${data.id}?token=${token}`" target="_blank">
                {{ data.file }}
              </a>
            </span>
          </li>
        </ul>
      </section>
    </el-collapse-transition>

    <div class="expand-button" @click.stop="expanded = !expanded">
      <i :class="expanded ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" />
    </div>
  </el-card>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      expanded: false
    }
  },

  computed: {
    token () {
      return localStorage.getItem('token') || ''
    },
    infoList () {
      return [
        {
          label: 'Statement Date',
          text: this.$date(this.data.createdAt, 'calendarFull')
        },
        {
          label: 'Start Date',
          text: this.$date(this.data.start, 'calendarFull')
        },
        {
          label: 'End Date',
          text: this.$date(this.data.end, 'calendarFull')
        },
        {
          label: 'Account',
          text: this.data.registration.accounts[0] ? this.data.registration.accounts[0].accountNumber : null
        },
        {
          label: 'Account Type',
          text: this.data.registration.type
        },
        {
          label: 'Profile',
          text: this.data.registration.profileNumber
        },
        {
          label: 'Profile Name',
          text: this.data.registration.name
        },
        {
          label: 'Number',
          text: this.data.number
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .statement-item + .statement-item {
    margin-top: 15px;
  }
</style>
