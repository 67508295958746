<template>
  <div>
    <error-box
      v-if="error"
      :loading="loading"
      @reload="load()" />

    <template v-else>
      <ms-filters
        :filters="appliedFilters"
        :filters-map="filtersMap"
        @apply="applyFilters($event)" />

      <div
        v-if="loading"
        v-loading="loading"
        class="page-loader" />

      <pull-to v-else :top-load-method="reload">
        <template v-if="!!statements.length">
          <statement-item
            v-for="statement in statements"
            :key="statement.id"
            :data="statement" />
        </template>

        <empty v-else>There are no data found.</empty>

        <template slot="top-block">
          <div>
            <div
              v-if="reloading"
              v-loading="reloading"
              class="basic-loader" />
          </div>
        </template>
      </pull-to>
    </template>
  </div>
</template>

<script>
import moment from 'moment'

import PullTo from 'vue-pull-to'
import Empty from '@/components/common/ui/Empty'
import ErrorBox from '@/components/common/ui/ErrorBox'
import StatementItem from '@/components/transactions/StatementItem'

export default {
  components: {
    Empty,
    PullTo,
    ErrorBox,
    StatementItem
  },

  data () {
    return {
      appliedFilters: null,
      error: null,
      loading: true,
      reloading: false,

      accountTypeOptions: window.accountTypeOptions
    }
  },

  computed: {
    filtersMap () {
      return [
        {
          key: 'date',
          label: 'Date Range',
          type: 'range_datepicker',
          validation: (value) => {
            if (!value?.from && !value?.to) return false
            const from = moment(value.from, 'YYYY-MM-DD')
            const to = moment(value.to, 'YYYY-MM-DD')
            return to.diff(from, 'days') > 60 ? 'Maximum date range is 60 days' : false
          }
        },
        {
          key: 'account',
          label: 'Account',
          type: 'select',
          options: this.accountOptions
        }
      ]
    },

    statements () {
      return this.$store.state.statements.data
    },

    registration () {
      return this.user?.registration || null
    },

    accounts () {
      return this.registration?.accounts || []
    },

    accountOptions () {
      return this.accounts.map(account => ({
        id: account.id,
        text: account.accountNumber
      }))
    },

    user () {
      return this.$store.state.app.user
    }
  },

  created () {
    this.setPageTitle()
    this.setDateFilterToCurrentMonth()
    this.load()
  },

  methods: {
    setDateFilterToCurrentMonth () {
      const today = moment()
      const month = today.format('MM')
      const year = today.format('YYYY')
      this.appliedFilters = {
        date: {
          from: `${year}-${month}-01`,
          to: today.format('YYYY-MM-DD')
        }
      }
    },

    applyFilters (filters) {
      this.appliedFilters = filters
      this.load()
    },

    setPageTitle () {
      this.$store.commit('page/setPageInfo', {
        title: 'Statements',
        back: { name: 'Home' }
      })
    },

    getParams () {
      const params = {
        regId: this.user.regId
      }

      if (!this.appliedFilters || !Object.values(this.appliedFilters).filter(val => val).length) {
        params.createdAt = ['>=', moment('00:00:00', 'HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')]
      } else {
        if (this.appliedFilters.date?.from) { params.createdAtAfter = ['>=', this.appliedFilters.date.from + ' 00:00:00', 'createdAt'] }
        if (this.appliedFilters.date?.to) { params.createdAtBefore = ['<=', this.appliedFilters.date.to + ' 23:59:59', 'createdAt'] }
        if (this.appliedFilters.account) { params.accountId = this.appliedFilters.account }
        if (this.appliedFilters.number) { params.accountType = this.appliedFilters.number }
      }

      return params
    },

    async load () {
      this.error = false
      this.loading = true
      try {
        const params = this.getParams()
        await this.$store.dispatch('statements/load', { order: ['ID:DESC'], params, skip: ['terms', 'serviceProviders', 'complianceScan', 'confirmation', 'profileSettings', 'documentsChecklist', 'documents', 'account', 'mainAccount', 'user', 'setupComplete', 'createdBy', 'contact', 'terms', 'serviceProviders', 'confirmation', 'profileSettings', 'documentsChecklist', 'documents', 'solidAccount', 'primeAccount'] })
      } catch {
        this.error = true
      } finally {
        this.loading = false
      }
    },

    async reload (loaded) {
      this.error = false
      this.reloading = true
      try {
        const params = this.getParams()
        await this.$store.dispatch('statements/load', { order: ['ID:DESC'], params, skip: ['terms', 'serviceProviders', 'complianceScan', 'confirmation', 'profileSettings', 'documentsChecklist', 'documents', 'account', 'mainAccount', 'user', 'setupComplete', 'createdBy', 'contact', 'terms', 'serviceProviders', 'confirmation', 'profileSettings', 'documentsChecklist', 'documents', 'solidAccount', 'primeAccount'] })
      } catch (e) {
        this.error = true
        console.error(e)
      } finally {
        loaded('done')
        this.reloading = false
      }
    }
  }
}
</script>
